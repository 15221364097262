<template>
  <v-container>
    <v-row>
      <v-col></v-col>
      <v-col class="text-right"
        ><v-btn :disabled="searching" @click="fetchSearch()" color="primary"
          ><v-icon class="mr-2">fas fa-sync</v-icon>Atualizar</v-btn
        >
      </v-col>
    </v-row>
    <v-card>
      <v-card-title>
        Produtos
        <v-spacer></v-spacer>
        <v-text-field
          v-model="searchResults"
          append-icon="fas fa-search"
          label="Search"
          single-line
          hide-details
        ></v-text-field>
      </v-card-title>
      <table-of-products
        v-if="searchResult && searchResult.data"
        :products="searchResult.data"
        :search="searchResults"
      />
    </v-card>
  </v-container>
</template>

<script>
import TableOfProductsVue from './../components/TableOfProducts'
// @ is an alias to /src
export default {
  name: 'amz',
  components: {
    'table-of-products': TableOfProductsVue
  },
  data() {
    return {
      selectedProd: null,
      dialog: false,
      search: null,
      ASIN: null,
      lstSearchStoreID: [],
      stores: [],
      limit: 10,
      prime: false,
      searchResults: null,
      searchResult: null,
      searching: false
    }
  },
  mounted() {
    this.fetchSearch()
  },
  created() {},
  methods: {
    fetchSearch() {
      var vm = this
      vm.searching = true
      vm.searchResult = null
      vm.$API
        .Request('post', `products/list`, {
          limit: 50000,
          last_minutes: 60
        })
        .then(result => {
          console.debug(result)
          vm.searchResult = result
          vm.searching = false
        })
        .catch(error => {
          vm.searching = false
          console.debug(error)
          vm.$snotify.error(error.message)
          if (
            error &&
            error.response &&
            error.response.data &&
            error.response.data.message
          ) {
            if (error.response.data.code == 200) {
              vm.$snotify.success(error.response.data.message)
            } else {
              vm.$snotify.error(error.response.data.message)
            }
          }
        })
    }
  }
}
</script>
